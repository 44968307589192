
export default {
  name: "SectionThematic",
  components: {
    CardVertical: () => import("@/components/cards/CardVertical"),
    CardHorizontal: () => import("@/components/cards/CardHorizontal"),
  },
  props: ["block", "seeAllLink", "isVertical", "radio"],
  computed: {
    getTitle() {
      let title = ""
      if(this.block.title) {
        title = this.block.title;
      } else if(this.block.label) {
        title = this.block.label;
      }

      if (this.radio) {
        title = title + " RCF " + this.radio;
      }

      return title;
    },
  }
};
